<template>
  <div class="w100p">
    <div class="flex-a-b-c h70 pr20 mb20">
      <div></div>
      <div class="d-flex">
        <el-button size="small" type="primary" @click="initialize_tap">
          恢复默认
        </el-button>
        <el-button size="small" type="primary" @click="save_template">
          保存
        </el-button>
      </div>
    </div>
    <div class="w100p d-flex">
      <!-- left -->
      <div class="w50p flex-c-c pt30 pb30">
        <div class="phoneClass borderboxd-flex flex-column">
          <div class="d-flex oyauto imgScroll" style="height:650px;width:100%;">
            <div class="felx1 text-align">
              <p class="fs10 " style="line-height: 40px;">商城</p>
              <!-- 商城模板 -->
              <div class="d-flex flex-column">
                <div
                  class=""
                  v-for="(moduleList, index) in ecommerceList"
                  :key="index"
                >
                  <!-- 轮播 -->
                  <div
                    class="borderbox pl5 pr5 mb18 posre"
                    v-if="moduleList.type == 'banner'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <el-carousel height="150px" indicator-position="none">
                      <el-carousel-item
                        v-for="(item, k) in moduleList.list"
                        :key="k"
                      >
                        <img :src="item.img" class="w100p h150" />
                      </el-carousel-item>
                    </el-carousel>
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>
                  <!-- 搜索 -->
                  <div
                    class="borderbox pl5 pr5 mb20 posre"
                    v-if="moduleList.type == 'search'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <img :src="moduleList.img" class="w100p" />
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>

                  <!-- tab -->
                  <div
                    class="w100p borderbox pl10 pr10 d-flex justify-content-between posre"
                    v-if="moduleList.type == 'tab'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <div
                      class="d-flex flex-column "
                      v-for="(item, k) in moduleList.list"
                      :key="k"
                    >
                      <img :src="item.img" class="h45 w45" />
                      <p style="color:#454A4Cl;font-size:13px;margin-top:5px">
                        {{ item.title }}
                      </p>
                    </div>
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="d-flex justify-content-between pl10 pr10 mt18 posre"
                    v-if="moduleList.type == 'diy1'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <div class="d-flex br10" style="width:152px;height:163px">
                      <img :src="moduleList.list[0].img" class="w100p" />
                    </div>
                    <div
                      class="d-flex flex-column justify-content-between"
                      style="width:152px"
                      v-if="index > 0"
                    >
                      <img
                        :src="moduleList.list[1].img"
                        class="w100p br10"
                        style="height:77px"
                      />
                      <img
                        :src="moduleList.list[2].img"
                        class="w100p br10"
                        style="height:77px"
                      />
                    </div>
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="pl10 mt18 w340 ohidden posre"
                    v-if="moduleList.type == 'seckill'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <div class="d-flex justify-content-between mb14">
                      <div class="pl10 pr10 flex-a-b-c w100p">
                        <div class="d-flex flex-a-c">
                          <span
                            class="bgbule d-block"
                            style="width:4px;height:18px"
                          ></span>
                          <p class="fs9 fw600 lineheight1 ml10">
                            {{ moduleList.title }}
                          </p>
                        </div>
                        <div
                          class="cblue fs7"
                          style="padding:4px 10px;border-radius:20px;border: 1px solid #51CBCD;"
                        >
                          更多 More+
                        </div>
                      </div>
                    </div>
                    <div class="d-flex oxauto  text-align-left mb17 ">
                      <div class="flex1 d-flex">
                        <div
                          class="d-flex flex-column"
                          :class="k == 2 ? '' : 'mr10'"
                          v-for="(item, k) in moduleList.list"
                          :key="k"
                        >
                          <img
                            :src="item.goodPhoto"
                            style="width:181px;height:181px"
                            class="br10 ohidden"
                          />
                          <div class="d-flex flex-column">
                            <p class="fs8 lineheight1 pt15 pb10">
                              {{ item.goodsName }}
                            </p>
                            <div class="d-flex pb10">
                              <p class="fs9 corange lineheight1 mr5">
                                ￥{{ item.minKillPrice / 100 || 0 }}
                              </p>
                              <div
                                class="d-flex align-items-end pb2 text-decoration fs6 cA3 lineheight1"
                              >
                                ￥{{ item.maxPrice / 100 || 0 }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="pl10 pr10 mb17 posre"
                    v-if="moduleList.type == 'banner1'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <img :src="moduleList.list[0].img" class="w100p h96 br10" />
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="pl10 pr10 mb17 mr10 ml10 pb10 group_bgcolor br10 posre"
                    v-if="moduleList.type == 'group'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <div
                      class="h45 w100p group_title lineheight45 fs9 cfff textc"
                    >
                      {{ moduleList.title }}
                    </div>
                    <div
                      class="pt10 pb10 pl10  d-flex bgfff br10"
                      :class="k == 0 ? 'mb10' : ''"
                      v-for="(item, k) in moduleList.list"
                      :key="k"
                    >
                      <img :src="item.goodPhoto" class="w77 h77 mr18 br6" />
                      <div
                        class="d-flex flex-column justify-content-between pb5 pt5 posre flex1"
                      >
                        <div class="flex-a-c">
                          <div
                            class="fs6 br10 cfff pb3 pb3 pl10 pr10 mr5 bgorange flex-c-c"
                          >
                            {{ item.peopleNum }}人团
                          </div>
                          <p class="fs8 fwbold">{{ item.goodsName }}</p>
                        </div>
                        <div class="d-flex">
                          <p class="fs9 corange lineheight1 mr5">
                            ￥{{ item.minAssemblePrice / 100 || 0 }}
                          </p>
                          <div
                            class="d-flex align-items-end pb2 text-decoration fs6 cA3 lineheight1"
                          >
                            ￥{{ item.maxPrice / 100 || 0 }}
                          </div>
                        </div>
                        <div class="posab purchase">
                          <i class="el-icon-shopping-cart-1 fs8 mr5"></i>
                          <p>抢购</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="pl10 pr10 mb17 posre"
                    v-if="moduleList.type == 'banner2'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <img :src="moduleList.list[0].img" class="w100p h96 br10" />
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="mr10 ml10 pb20 posre"
                    v-if="moduleList.type == 'boutique'"
                    @click="selected_tap(moduleList.type)"
                  >
                    <div class="flex-c-c posre fs9 fwbold lineheight1 mb15">
                      {{ moduleList.title }}
                      <img
                        src="../../../static/ecommerce/img_tag_cardcase.png"
                        class="w100 h10 posab choicenessBgImg"
                      />
                    </div>

                    <div class="choicenessBox mb10">
                      <img
                        :src="moduleList.list[0].goodPhoto"
                        class="w90 h90 mr40"
                      />
                      <div class="flex1 textc flex-a-b-c flex-column">
                        <div>
                          <p class="fs8 fwbold">
                            {{ moduleList.list[0].goodsName }}
                          </p>
                          <p class="fs6 cA3">{{ moduleList.list[0].tips }}</p>
                        </div>
                        <p class="fs8 corange lineheight1 mr5">
                          ￥{{ moduleList.list[0].price / 100 }}
                        </p>
                      </div>
                    </div>

                    <div class="choicenessBox">
                      <div class="flex1 textc flex-a-b-c flex-column">
                        <div>
                          <p class="fs8 fwbold">
                            {{ moduleList.list[1].goodsName }}
                          </p>
                          <p class="fs6 lineheight20 cA3">
                            {{ moduleList.list[1].tips }}
                          </p>
                        </div>
                        <p class="fs8 corange lineheight1 mr5">
                          ￥{{ moduleList.list[1].price / 100 }}
                        </p>
                      </div>
                      <img
                        :src="moduleList.list[1].goodPhoto"
                        class="w90 h90 ml40"
                      />
                    </div>
                    <div
                      class="posab control"
                      :class="
                        moduleList.type == currentType ? 'fade_in' : 'fade_out'
                      "
                    >
                      <i
                        class="el-icon-caret-top cfff"
                        @click.stop="templateUp(index)"
                      ></i>
                      <i
                        class="el-icon-caret-bottom cfff"
                        @click.stop="templateDown(index)"
                      ></i>
                      <i
                        class="el-icon-delete cfff"
                        @click.stop="templateDelete(index)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex1 bgfff justify-content-between">
            <div
              style="width:52px;height:100%"
              class="flex-c-c flex-column"
              v-for="(item, index) in tabList"
              :key="index"
            >
              <img
                :src="index == 2 ? item.selectedIconPath : item.iconPath"
                class="w40 h40"
              />
              <p class="fs6">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="w50p posre pt10 d-flex oyauto oscrollbar">
        <div class="flex1 h550 pt20 pl20 pb20 w100p">
          <div
            class="posab top0 mt20"
            v-for="(moduleList, index) in ecommerceList"
            :key="index"
          >
            <!-- 轮播 -->
            <div
              class="pt20  pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-if="currentType == 'banner' && moduleList.type == currentType"
            >
              <p class="textc fs9 mb20 fwbold">轮播图</p>
              <div
                class="bgf7fa pa10 d-flex align-items-center mb20 posre "
                v-for="(item, k) in moduleList.list"
                :key="k"
              >
                <div class="d-flex flex-column align-items-center ">
                  <el-upload
                    accept="image/jpeg,image/png"
                    class="addTabIcon"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="
                      bannerPicSuccess.bind(null, {
                        type: currentType,
                        index: k,
                        data: item,
                      })
                    "
                    :before-upload="beforeUploadImageDynamicPic"
                  >
                    <img
                      v-if="item.img"
                      :src="item.img"
                      class="avatar dynamic "
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <p class="fs6">建议上传690px * 324px的图片效果最佳</p>
                </div>
                <jumpSet
                  :goodsData="item"
                  :index="currentIndex"
                  :currentGoods="item"
                  :currentSelect="k"
                  @currentSelect_tap="currentSelect_tap"
                  @select_tap="select_tap"
                ></jumpSet>
              </div>
            </div>
            <!-- tab -->
            <div
              class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="currentType == 'tab' && moduleList.type == currentType"
            >
              <tab
                :listData="moduleList"
                @delete_seckill="delete_seckill"
                @add_seckill="add_seckill"
                @confirmSeckill="confirmSeckill"
                @reset_tab_tap="reset_tab_tap"
              >
              </tab>
            </div>
            <!-- diy1 -->
            <div
              class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="
                currentType == 'diy1' && moduleList.type == currentType
              "
            >
              <p class="textc fs9 mb20 fwbold">图片模块</p>
              <div
                class="bgf7fa pa10 d-flex align-items-center mb20 posre "
                v-for="(item, k) in moduleList.list"
                :key="k"
              >
                <div class="d-flex flex-column align-items-center ">
                  <el-upload
                    accept="image/jpeg,image/png"
                    class="addTabIcon"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="
                      bannerPicSuccess.bind(null, {
                        type: currentType,
                        index: k,
                        data: item,
                      })
                    "
                    :before-upload="beforeUploadImageDynamicPic"
                  >
                    <img
                      v-if="item.img"
                      :src="item.img"
                      class="avatar dynamic "
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <p class="fs6">
                    建议上传{{
                      k == 0 ? "690px * 324px" : "690px * 170px"
                    }}的图片效果最佳
                  </p>
                </div>
                <jumpSet
                  :goodsData="item"
                  :index="currentIndex"
                  :currentGoods="item"
                  :currentSelect="k"
                  @currentSelect_tap="currentSelect_tap"
                  @select_tap="select_tap"
                ></jumpSet>
              </div>
            </div>
            <!-- 秒杀 -->
            <div
              class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="
                currentType == 'seckill' && moduleList.type == currentType
              "
            >
              <specialGoods
                :listData="moduleList"
                type="seckill"
                @delete_seckill="delete_seckill"
                @add_seckill="add_seckill"
                @confirmSeckill="confirmSeckill"
              >
              </specialGoods>
            </div>
            <!-- banner1 -->
            <div
              class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="
                currentType == 'banner1' && moduleList.type == currentType
              "
            >
              <p class="textc fs9 mb20 fwbold">图片模块</p>
              <div
                class="bgf7fa pa10 d-flex align-items-center mb20 posre "
                v-for="(item, k) in moduleList.list"
                :key="k"
              >
                <div class="d-flex flex-column align-items-center ">
                  <el-upload
                    accept="image/jpeg,image/png"
                    class="addTabIcon"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="
                      bannerPicSuccess.bind(null, {
                        type: currentType,
                        index: k,
                        data: item,
                      })
                    "
                    :before-upload="beforeUploadImageDynamicPic"
                  >
                    <img
                      v-if="item.img"
                      :src="item.img"
                      class="avatar dynamic "
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <p class="fs6">建议上传690px * 210px的图片效果最佳</p>
                </div>
                <jumpSet
                  :goodsData="item"
                  :index="currentIndex"
                  :currentGoods="item"
                  :currentSelect="k"
                  @currentSelect_tap="currentSelect_tap"
                  @select_tap="select_tap"
                ></jumpSet>
              </div>
            </div>

            <!-- 拼团 -->
            <div
              class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="
                currentType == 'group' && moduleList.type == currentType
              "
            >
              <specialGoods
                :listData="moduleList"
                type="group"
                @delete_seckill="delete_seckill"
                @add_seckill="add_seckill"
                @confirmSeckill="confirmSeckill"
              >
              </specialGoods>
            </div>
            <!-- banner2 -->
            <div
              class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="
                currentType == 'banner2' && moduleList.type == currentType
              "
            >
              <p class="textc fs9 mb20 fwbold">图片模块</p>
              <div
                class="bgf7fa pa10 d-flex align-items-center mb20 posre "
                v-for="(item, k) in moduleList.list"
                :key="k"
              >
                <div class="d-flex flex-column align-items-center ">
                  <el-upload
                    accept="image/jpeg,image/png"
                    class="addTabIcon"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="
                      bannerPicSuccess.bind(null, {
                        type: currentType,
                        index: k,
                        data: item,
                      })
                    "
                    :before-upload="beforeUploadImageDynamicPic"
                  >
                    <img
                      v-if="item.img"
                      :src="item.img"
                      class="avatar dynamic "
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <p class="fs6">建议上传690px * 210px的图片效果最佳</p>
                </div>
                <jumpSet
                  :currentGoods="item"
                  :index="currentIndex"
                  :currentSelect="k"
                  @currentSelect_tap="currentSelect_tap"
                  @select_tap="select_tap"
                ></jumpSet>
              </div>
            </div>
            <!-- 精选商品 -->
            <div
              class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="
                currentType == 'boutique' && moduleList.type == currentType
              "
            >
              <specialGoods
                :listData="moduleList"
                type="isOrdinary"
                :showTips="true"
                @delete_seckill="delete_seckill"
                @add_seckill="add_seckill"
                @confirmSeckill="confirmSeckill"
              >
              </specialGoods>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  saveShopModelConfig,
  queryShopModelConfig,
  updateShopModelConfig,
} from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
import jumpSet from "./components/jumpSet";
import specialGoods from "./ecommerce/specialGoods";
import tab from "./ecommerce/tab";

export default {
  data() {
    return {
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      isUpdate: false,
      tabList: [
        {
          text: "名片",
          pagePath: "pages/index/main",
          iconPath:
            this.$store.state.ossUrl + "yimai_photos/tabbarIcon/card.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "yimai_photos/tabbarIcon/card_select.png",
        },
        {
          text: "媒体",
          pagePath: "pages/media/main",
          iconPath:
            this.$store.state.ossUrl + "yimai_photos/tabbarIcon/video.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "yimai_photos/tabbarIcon/video_select.png",
        },
        {
          text: "商城",
          pagePath: "pages/Products/main",
          iconPath:
            this.$store.state.ossUrl + "yimai_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "yimai_photos/tabbarIcon/prod_select.png",
        },
        {
          text: "动态",
          pagePath: "pages/Dynamic/main",
          iconPath:
            this.$store.state.ossUrl + "yimai_photos/tabbarIcon/browser.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "yimai_photos/tabbarIcon/browser_select.png",
        },
        {
          text: "官网",
          pagePath: "pages/WebSite/main",
          iconPath:
            this.$store.state.ossUrl + "yimai_photos/tabbarIcon/computer.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "yimai_photos/tabbarIcon/computer_select.png",
        },
      ],
      ecommerceList: [
        {
          type: "banner",
          list: [
            {
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/banner1.png",
              page: "",
              goods: "",
            },
            {
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/banner2.png",

              page: "",
              goods: "",
            },
          ],
        },
        {
          img: require("../../../../src/static/icon_search.png"),
          type: "search",
        },
        {
          list: [
            {
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/tab1.png",

              title: "衣服",
            },
            {
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/tab2.png",
              title: "裤子",
            },
            {
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/tab3.png",
              title: "帽子",
            },
            {
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/tab4.png",
              title: "鞋子",
            },
          ],

          type: "tab",
        },
        {
          list: [
            {
              goods: "",
              page: "",
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/diyA1.png",
            },
            {
              goods: "",
              page: "",
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/diyA2.png",
            },
            {
              goods: "",
              page: "",
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/diyA3.png",
            },
          ],
          type: "diy1",
        },
        {
          type: "seckill",
          title: "特价秒杀",
          more: [
            {
              page: "",
              goods: "",
            },
          ],
          list: [
            {
              goodsName: "Lining中国李宁短袖",
              goodsId: "",
              goodPhoto: require("../../../../src/static/ecommerce/goodsA1.png"),
              minKillPrice: "88800",
              maxPrice: "888800",
            },
            {
              goodsName: "Lining李宁运动鞋",
              goodsId: "",
              goodPhoto: require("../../../../src/static/ecommerce/goodsA2.png"),
              minKillPrice: "88800",
              maxPrice: "888800",
            },
            {
              goodsName: "Lining李宁运动鞋",
              goodsId: "",
              goodPhoto: require("../../../../src/static/ecommerce/goodsA3.png"),
              minKillPrice: "88800",
              maxPrice: "888800",
            },
          ],
        },
        {
          type: "banner1",
          list: [
            {
              goods: "",
              page: "",
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/bannerA1.png",
            },
          ],
        },
        {
          type: "group",
          title: "拼团抢购",
          list: [
            {
              goodsName: "夏季新款T恤",
              goodsId: "",
              goodsNum: 2,
              goodPhoto: require("../../../../src/static/ecommerce/goodsB1.png"),
              minAssemblePrice: "88800",
              maxPrice: "888800",
            },
            {
              goodsName: "宽松印花T恤",
              goodsId: "",
              goodsNum: 2,
              goodPhoto: require("../../../../src/static/ecommerce/goodsB2.png"),
              minAssemblePrice: "88800",
              maxPrice: "888800",
            },
          ],
        },
        {
          type: "banner2",
          list: [
            {
              goods: "",
              page: "",
              img:
                "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/ecommerce/bannerB1.png",
            },
          ],
        },
        {
          type: "boutique",
          title: "精品推荐",
          list: [
            {
              goodsName: "李宁高帮鞋",
              goodPhoto: require("../../../../src/static/ecommerce/goodsC1.png"),
              tips: "穿上它，你就是最靓的仔",
              price: "88800",
              maxPrice: "888800",
            },
            {
              goodsName: "黑色潮鞋",
              tips: "冷酷潮鞋，值得拥有轻便舒适，休闲运动",
              goodPhoto: require("../../../../src/static/ecommerce/goodsC2.png"),
              price: "88800",
              maxPrice: "888800",
            },
          ],
        },
      ],
      currentType: "",
      currentIndex: 0,
      currentSelect: "",
      initializeData: "",
      requestWay: "save",
    };
  },
  components: {
    jumpSet,
    specialGoods,
    tab,
  },
  created() {
    this.initializeData = JSON.parse(JSON.stringify(this.ecommerceList));
    console.log(this.initializeData);
    this.queryShopConfig();
  },
  mounted() {},
  methods: {
    // 获取商城模板
    queryShopConfig() {
      let data = {
        modelEnum: "ecommerce",
      };
      let than = this;
      queryShopModelConfig(data).then((res) => {
        console.log(res);
        if (res.data.modelId) {
          than.requestWay = "up";
          than.ecommerceList = JSON.parse(res.data.shopDetails[0].content);
          than.ecommerceList.forEach((v, k) => {
            if (v.type == "seckill") {
              than.ecommerceList[k].more = [];
              than.ecommerceList[k].more.push({
                page: "",
                goods: "",
              });
            }
          });
        }
        console.log(this.ecommerceList);
      });
    },
    selected_tap(type) {
      let index = "";
      this.ecommerceList.forEach((item, k) => {
        if (item.type == type) {
          this.currentIndex = k;
        }
      });
      console.log(this.currentIndex);
      this.currentType = type;
    },
    bannerPicSuccess(obj, res, file) {
      //动态图上传成功
      let than = this;
      console.log(obj);
      this.ecommerceList.forEach((item, k) => {
        if (item.type == obj.type) {
          than.$set(
            than.ecommerceList[k].list[obj.index],
            "img",
            this.ossUrl + "/" + res.data
          );
        }
      });
    },

    // 上移
    templateUp() {
      console.log(this.currentIndex, 111111111111);
      let arr = this.ecommerceList,
        index = this.currentIndex;
      if (index !== 0) {
        this.swapArray(arr, this.currentIndex, --this.currentIndex);
      } else {
        this.$message.error("已经处于置顶，无法上移");
      }
    },
    templateDown() {
      console.log(this.currentIndex, 222222222222);
      let arr = this.ecommerceList,
        length = this.ecommerceList.length,
        index = this.currentIndex;
      if (index + 1 != length) {
        this.swapArray(arr, this.currentIndex, ++this.currentIndex);
      } else {
        this.$message.error("已经触底，无法下移");
      }
    },
    templateDelete() {
      let index = this.currentIndex;
      this.ecommerceList.splice(index, 1);
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    beforeUploadImageDynamicPic(file) {
      var _this = this;
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (["image/jpeg", "image/png"].indexOf(file.type) == -1) {
        _this.$message.error("请上传正确的图片格式");
        return false;
      }
      if (!isLt10M) {
        _this.$message.error("上传图片大小不能超过10MB哦!");
        return false;
      }
    },
    currentSelect_tap(index) {
      this.currentSelect = index;
    },
    select_tap(index, val, type, goods, currentSelect) {
      if (goods) {
        if (currentSelect || currentSelect === 0) {
          this.ecommerceList[this.currentIndex].list[
            currentSelect
          ].goods = goods;
          delete this.ecommerceList[this.currentIndex].list[currentSelect].page;
        } else {
          this.ecommerceList[this.currentIndex].list[
            this.currentSelect
          ].goods = goods;
          delete this.ecommerceList[this.currentIndex].list[this.currentSelect]
            .page;
        }
      } else {
        if (type) {
          if (currentSelect || currentSelect === 0) {
            this.ecommerceList[this.currentIndex].list[
              currentSelect
            ].page = type;
            delete this.ecommerceList[this.currentIndex].list[currentSelect]
              .goods;
          } else {
            this.ecommerceList[this.currentIndex].list[
              this.currentSelect
            ].page = type;
            delete this.ecommerceList[this.currentIndex].list[
              this.currentSelect
            ].goods;
          }
        }
      }
    },
    delete_seckill(index, type) {
      let than = this;
      than.ecommerceList.forEach((item, k) => {
        if (item.type == type) {
          than.ecommerceList[k].list.splice(index, 1);
        }
      });
    },
    add_seckill(type) {
      let than = this;
      than.ecommerceList.forEach((item, k) => {
        if (item.type == type) {
          console.log(than.ecommerceList[k].list, "listdata");
          than.ecommerceList[k].list.push({
            title: "",
            newPrice: "",
          });
        }
      });
      console.log(than.ecommerceList);
    },
    //重置数据
    initialize_tap() {
      this.ecommerceList = JSON.parse(JSON.stringify(this.initializeData));
    },
    //保存模板
    save_template() {
      let than = this;
      let arr = JSON.parse(JSON.stringify(this.ecommerceList));
      console.log(arr);
      let p = new Promise((resolve, reject) => {
        arr.forEach((item, k) => {
          if (item.list) {
            item.list.forEach((v, k) => {
              if (item.type == "seckill") {
                if (!v.goodsId) {
                  reject("秒杀模块未设置商品");
                }
              }
              if (item.type == "group") {
                if (!v.goodsId) {
                  reject("拼团模块未设置商品");
                }
              }
              if (item.type == "boutique") {
                if (!v.goodsId) {
                  reject("精选模块未设置商品");
                }
              }
            });
          }
        });
        resolve("succ");
      });
      p.then((res) => {
        console.log(res, "22222");
        let data = {
          modelEnum: "ecommerce",
          shopDetails: [
            {
              content: JSON.stringify(arr),
            },
          ],
        };
        if (than.requestWay == "save") {
          saveShopModelConfig(data)
            .then((res) => {
              if (res.code == 200) {
                than.$message.success("保存成功");
                setTimeout(() => {
                  than.queryShopConfig();
                  than.$router.push("mallSetting");
                }, 500);
              }
            })
            .catch((err) => {
              if (err.code == 201) {
                this.$message.error(err.message);
              }
              console.log(err, "wwwww");
            });
        } else {
          updateShopModelConfig(data)
            .then((res) => {
              if (res.code == 200) {
                than.$message.success("保存成功");
                setTimeout(() => {
                  than.queryShopConfig();
                  than.$router.push("mallSetting");
                }, 500);
              }
            })
            .catch((err) => {
              if (err.code == 201) {
                this.$message.error(err.message);
              }
              console.log(err, "wwwww");
            });
        }
      }).catch((err) => {
        this.$message.error(err);
        console.log(err, "wwwww");
      });
    },
    //重置tab
    reset_tab_tap(data) {
      let than = this;
      than.ecommerceList.forEach((item, k) => {
        if (item.type == "tab") {
          than.$set(than.ecommerceList, k, data);
        }
      });
    },
    //确认秒杀商品
    confirmSeckill(index, data, type) {
      let than = this;
      than.ecommerceList.forEach((item, k) => {
        if (item.type == type) {
          than.ecommerceList[k].list[index] = data;
          than.$set(than.ecommerceList[k].list, index, data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.phoneClass {
  padding: 50px 30px 0px 30px;
  background-image: url("../../../assets/images/iphonex.png");
  width: 400px;
  height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.imgScroll::-webkit-scrollbar {
  width: 0 !important;
}
.group_title {
  background-image: url("../../../static/ecommerce/bg1.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.group_bgcolor {
  background: linear-gradient(0deg, rgba(253, 78, 86, 0.3), #fd4e56);
}
.purchase {
  width: 74px;
  height: 32px;
  background: #ffa133;
  color: #fff;
  border-radius: 16px 0px 0px 16px;
  right: 0px;
  top: 45px;
  display: flex;
  font-weight: bold;
  align-items: center;
  padding-left: 11px;
  font-size: 16px;
}
.choicenessBgImg {
  bottom: 0px;
}
.choicenessBox {
  padding: 18px;
  width: 100p;
  background: #ffffff;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  display: flex;
}
.fade_in {
  opacity: 1;
  z-index: 99;
  transition: all 0.2s;
}
.fade_out {
  opacity: 0;
  display: none !important;
  transition: all 0.2s;
}
.control {
  right: 0px;
  top: 0px;
  width: 25px;
  height: 150px;
  background: #51cdcb;
  z-index: 99;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 18px;
}
/deep/.addTabIcon .el-upload--text {
  width: 210px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 10px;
}
/deep/ .addTabIcon .el-upload-list__item {
  width: 210px;
  height: 100px;
  line-height: 100px;
}
.avatar {
  width: 100%;
  height: 100%;
}
.top0 {
  top: 0px;
}
</style>
